.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound p {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}
.notfound-404 {
  height: 280px;
  position: relative;
  z-index: -1;
}

.notfound h1 {
  background-image: url("../img/404Bg.jpg");
  font-size: 210px;
  margin: 0px;
  font-weight: 900;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  color: red;
}

#notfound {
  position: relative;
  height: 100vh;
}
