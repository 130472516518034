body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  background: #20232b;
}

* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: normal;
}

#root,
body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* SCROLL BARS */
* {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(35, 37, 49, 1) #171719; /* Firefox */
}

*::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: rgba(26, 29, 36, 1); /* Track background */
}

*::-webkit-scrollbar-thumb {
  background-color: #171719; /* Scrollbar thumb color */
  border-radius: 10px; /* Roundness of the scroll thumb */
  border: 4px solid rgba(26, 29, 36, 1); /* Space around the thumb */
}

/* Cookie consent */
body {
  --cc-bg: #fcfbfa;
  --cc-text: #1f1f1f;
  --cc-link: #1f1f1f;
  --cc-btn-primary-bg: rgba(112, 105, 253, 1);
  --cc-btn-primary-text: #ffffff;
  --cc-btn-primary-hover-bg: rgb(100, 93, 250);
  --cc-btn-primary-hover-text: #000;
  --cc-btn-secondary-bg: rgb(235, 234, 250);
  --cc-btn-secondary-text: #1f1f1f;
  --cc-btn-secondary-hover-bg: rgba(96, 96, 84, 0.796);
  --cc-btn-secondary-hover-text: #000;
  --cc-toggle-bg-off: rgb(212, 210, 247);
  --cc-toggle-bg-on: rgba(112, 105, 253, 1);
  --cc-toggle-bg-readonly: rgba(112, 105, 253, 1);
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #fcfbfa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #fcfbfa;
  --cc-cookie-category-block-bg-hover: #dbe5f9;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e1e7f3;
  --cc-overlay-bg: rgba(230, 235, 255, 0.85);
  --cc-webkit-scrollbar-bg: #ebeff9;
  --cc-webkit-scrollbar-bg-hover: #fcfbfa;
}
